<template>
  <div v-if="plugin" class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("plugins.pluginDetails") }} - {{ plugin.label }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary" @click="goBack">
          {{ $t("general.backToOverview") }}
        </button>
      </div>
    </div>
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <div class="col-md-12">
          <h5>{{ plugin.description }}</h5>
        </div>
        <div class="col-md-12 border-bottom">
          <h4 class="mb-5 font-weight-bold text-dark">
            {{ $t("plugins.status") }}
          </h4>
          <div class="form-group row align-items-center">
            <div class="col-md-12 col-lg-6 col-xl-4">
              <div class="d-flex mb-7">
                <div
                  class="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1"
                >
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                    <span
                      v-if="plugin.isInstalled"
                      class="text-gray-400 fw-bold fs-7 my-1"
                    >
                      {{
                        $t("plugins.statusInstalledBy", {
                          user: plugin.installed_by.name,
                          date: dateFormatter(plugin.installed_at)
                        })
                      }}
                    </span>
                    <span
                      v-if="plugin.isInstalled"
                      class="text-gray-400 fw-bold fs-7 my-1"
                    >
                      {{ $t("plugins.updatedAt") }}:
                      {{ dateFormatter(plugin.updated_at) }}
                    </span>
                    <span
                      v-if="!plugin.isInstalled"
                      class="text-gray-400 fw-bold fs-7 my-1"
                    >
                      {{ $t("plugins.statusNotInstalled") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="plugin.workflowElements" class="col-md-12 border-bottom">
          <h4 class="mb-5 font-weight-bold text-dark">
            {{ $t("plugins.workflowElements") }}
          </h4>
          <div class="form-group row align-items-center">
            <b-row class="p-4">
              <b-col cols="4"
                     v-for="(entry, index) in plugin.workflowElements"
                     :key="index"
              >
                <div class="row">
                  <div class="flex-wrap justify-content-center col-6 d-flex">
                    <div class="designer-element rounded-circle border position-relative bg-white">
                      <span :style="{ backgroundImage: `url(${entry.icon.link})` }"></span>
                    </div>
                    <div class="w-100 text-center">
                      <span>{{ entry.label }}</span>
                      <p><small>{{ entry.type }}</small></p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!--
            <div
              v-for="(entry, index) in plugin.workflowElements"
              :key="index"
              class="col-md-4 col-lg-3 col-xl-2"
            >
              <div class="card card-custom d-flex flex-stack">
                <div class="d-flex align-items-center">
                  <div v-if="entry.icon" class="symbol symbol-lg-75 me-5 p-2">
                    <span
                      class="symbol-label bg-primary-light"
                      style="padding: 50px"
                    >
                      <img
                        style="height: 30px"
                        :src="entry.icon.link"
                        class="align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                  <div
                    class="d-flex flex-column flex-grow-1 my-lg-0 p-5 text-center"
                  >
                    <a
                      href="#"
                      class="text-dark fw-bolder text-hover-primary fs-5"
                      >{{ entry.label }}</a
                    >
                    <span class="text-muted fw-bold">{{ entry.type }}</span>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
        <div v-if="plugin.eventSubscribers" class="col-md-12 border-bottom">
          <h4 class="mb-5 font-weight-bold text-dark">
            {{ $t("plugins.eventSubscribers") }}
          </h4>
          <div class="form-group row align-items-center">
            <div
              v-for="(entry, index) in plugin.eventSubscribers"
              :key="index"
              class="col-md-12 col-lg-6 col-xl-4"
            >
              {{ entry.classname }}
            </div>
          </div>
        </div>
        <div v-if="plugin.connectors" class="col-md-12 border-bottom">
          <h4 class="mb-5 font-weight-bold text-dark">
            {{ $t("plugins.connectors") }}
          </h4>
          <div class="form-group row align-items-center">
            <b-row class="p-4">
              <b-col cols="4"
                     v-for="(entry, index) in plugin.connectors"
                     :key="index"
              >
                <div class="row">
                  <div class="flex-wrap justify-content-center col-6 d-flex">
                    <!--
                    <div class="designer-element rounded-circle border position-relative bg-white">
                      <span :style="{ backgroundImage: `url(${entry.icon.link})` }"></span>
                    </div>
                    -->
                    <div class="w-100 text-center">
                      <span>{{ entry.label }}</span>
                      <p><small>{{ entry.classname }}</small></p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!--
            <div
              v-for="(entry, index) in plugin.workflowElements"
              :key="index"
              class="col-md-4 col-lg-3 col-xl-2"
            >
              <div class="card card-custom d-flex flex-stack">
                <div class="d-flex align-items-center">
                  <div v-if="entry.icon" class="symbol symbol-lg-75 me-5 p-2">
                    <span
                      class="symbol-label bg-primary-light"
                      style="padding: 50px"
                    >
                      <img
                        style="height: 30px"
                        :src="entry.icon.link"
                        class="align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                  <div
                    class="d-flex flex-column flex-grow-1 my-lg-0 p-5 text-center"
                  >
                    <a
                      href="#"
                      class="text-dark fw-bolder text-hover-primary fs-5"
                      >{{ entry.label }}</a
                    >
                    <span class="text-muted fw-bold">{{ entry.type }}</span>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";

export default {
  name: "PluginDetails",
  data() {
    return {
      plugin: null,
      workflowImageProps: {
        blank: false,
        blankColor: "#777",
        width: 120,
        height: 120,
        class: "m1"
      }
    };
  },
  mounted() {
    if (this.$route.params.plugin) {
      this.plugin = this.$route.params.plugin ?? null;
    } else {
      this.goBack();
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "adminSettingsPlugins" });
    },
    dateFormatter(value) {
      return formatDate(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.designer-element {
  width: 50px;
  height: 50px;
  border-color: #9babbd !important;
  position: relative;

  span {
    background-position: center;
    background-size: contain;
    width: 66%;
    height: 66%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
