<template>
  <b-row>
    <b-col md="12">
      <Table v-if="$route.name === 'adminSettingsPlugins'" />
      <Details v-if="$route.name === 'adminSettingsPluginsDetails'" />
    </b-col>
  </b-row>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "@/components/Admins/Settings/Plugins/Table";
import Details from "@/components/Admins/Settings/Plugins/Details";

export default {
  name: "AdminsSettingsPluginIndex",
  components: { Table, Details },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsSystem"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsPlugins"),
        route: "/admin/settings/plugins"
      }
    ]);
  }
};
</script>
